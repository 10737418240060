import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import Lottie from "react-lottie-player";
import * as successLottie from "../../assets/animations/success.json";
import * as errorLottie from "../../assets/animations/error.json";
import * as warningLottie from "../../assets/animations/warning.json";
import * as announcementLottie from "../../assets/animations/announcement.json";

interface Props {
  title: string;
  description?: string;
  open: boolean;
  onClose: () => void;
  type: "success" | "error" | "warning" | "announcement";
  onConfirm?: () => void;
  loading?: boolean;
  html?: string;
}

const lottieFiles = {
  success: successLottie,
  warning: warningLottie,
  error: errorLottie,
  announcement: announcementLottie,
};

const AlertModal: FC<Props> = ({
  open,
  onClose,
  onConfirm,
  title,
  description,
  html,
  type,
  loading,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Stack alignItems="center" p={2} pt={0}>
          <Lottie
            play
            loop={false}
            animationData={lottieFiles[type]}
            style={{ width: 200, height: 200 }}
          />
          <Typography variant="h3" mb={2}>
            {title}
          </Typography>
          <Typography align="center" mb={4}>
            {html ? (
              <div dangerouslySetInnerHTML={{ __html: html }} />
            ) : (
              description
            )}
          </Typography>
          <Stack direction="row" justifyContent="space-around" width="100%">
            <Button
              sx={{ width: 150 }}
              variant={onConfirm ? "outlined" : "contained"}
              size="large"
              onClick={onClose}
            >
              {onConfirm ? "Cancel" : "Close"}
            </Button>
            {onConfirm && (
              <Button
                disabled={loading}
                sx={{ width: 150 }}
                size="large"
                onClick={onConfirm}
              >
                {loading ? <CircularProgress size={24} /> : "Confirm"}
              </Button>
            )}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AlertModal;
