import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { formatNumber } from "../../utils/formatNumber";
import AlertModal from "../AlertModal";
import { useAccount, useSecretNetworkClient } from "../../models/account";
import { MsgExecuteContract } from "secretjs";
import { useFinaTokenInfo } from "../../models/finaTokenInfo";

const stakingContract = process.env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS || "";
const stakingContractCodeHash =
  process.env.NEXT_PUBLIC_STAKING_CONTRACT_CODE_HASH || "";
const finaToken = process.env.NEXT_PUBLIC_FINA_TOKEN_ADDRESS || "";
const finaCodeHash = process.env.NEXT_PUBLIC_FINA_TOKEN_CODE_HASH || "";
const finaTokenDecimals = Number(
  process.env.NEXT_PUBLIC_FINA_TOKEN_DECIMALS || 6
);

interface Props {
  open: boolean;
  onClose: () => void;
  max: number;
  onSuccess: () => void;
  lockUpDays: number;
  defaultAmount?: number;
}

const StakeModal: FC<Props> = ({
  open,
  onClose,
  max,
  onSuccess,
  lockUpDays,
  defaultAmount,
}) => {
  const { stakingInfo } = useFinaTokenInfo();
  const secretjs = useSecretNetworkClient();
  const { account } = useAccount();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [amount, setAmount] = useState(
    defaultAmount ? defaultAmount.toFixed(6) : ""
  );

  useEffect(() => {
    setAmount(defaultAmount ? defaultAmount.toFixed(6) : "");
  }, [open, defaultAmount]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (!secretjs) {
              return;
            }
            try {
              setError("");
              setLoading(true);
              const msgs = [
                stakingInfo.staked > 0
                  ? new MsgExecuteContract({
                      sender: account.address,
                      contract_address: stakingContract,
                      code_hash: stakingContractCodeHash,
                      msg: { claim: {} },
                    })
                  : undefined,
                new MsgExecuteContract({
                  sender: account.address,
                  contract_address: finaToken,
                  code_hash: finaCodeHash,
                  msg: {
                    send: {
                      recipient: stakingContract,
                      amount: String(
                        Math.round(Number(amount) * 10 ** finaTokenDecimals)
                      ),
                      msg: "eyAic3Rha2UiOiB7fSB9",
                    },
                  },
                }),
              ].filter((a) => !!a);
              const tx = await secretjs.tx.broadcast(msgs as any, {
                gasLimit: msgs.length > 1 ? 700000 : 500000,
                gasPriceInFeeDenom: 0.25,
              });
              console.log({ tx });
              if (!tx.jsonLog) {
                throw new Error(tx.rawLog);
              }
              await onSuccess();
              setLoading(false);
              setSuccess(
                `You have successfully staked ${formatNumber(
                  Number(amount)
                )} FINA!`
              );
              onClose();
            } catch (err: any) {
              setLoading(false);
              setError(err.message);
            }
          }}
        >
          <DialogTitle>Stake FINA</DialogTitle>
          <DialogContent>
            <Stack mt={2} mb={1} direction="row" justifyContent="space-between">
              <Typography variant="body2">Amount</Typography>
              <Typography variant="body2">
                <Link
                  underline="hover"
                  sx={{ cursor: "pointer", ml: 1 }}
                  onClick={() => setAmount(String(max))}
                >
                  Available: {formatNumber(max || 0)} FINA
                </Link>
              </Typography>
            </Stack>
            <TextField
              value={amount}
              error={!!error && error !== "empty"}
              onChange={(e) => {
                if (
                  !e.target.value.match(/[^.0-9]/) &&
                  (e.target.value.match(/\./g) || []).length < 2
                ) {
                  setAmount(e.target.value);
                }
              }}
              placeholder="0.0"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">FINA</InputAdornment>
                ),
              }}
            />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              my={3}
            >
              <Typography variant="body2">You Staked</Typography>
              <Typography variant="h6" align="right">
                {formatNumber(stakingInfo.staked, 6)} FINA
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              my={3}
            >
              <Typography variant="body2">Lock Up Period</Typography>
              <Typography variant="h6">{lockUpDays} Days</Typography>
            </Stack>
            <Typography variant="body2">
              If you already have FINA staked before, your unlock date will not
              be affected by staking more FINA
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ mr: 1 }}
              variant="text"
              size="large"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              disabled={loading || Number(amount) > max}
              size="large"
              type="submit"
            >
              {loading ? (
                <CircularProgress sx={{ mx: 1.5 }} size={25} />
              ) : (
                "Confirm"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <AlertModal
        open={!!success}
        onClose={() => setSuccess("")}
        title="Success"
        description={success}
        type="success"
      />
      <AlertModal
        open={!!error}
        onClose={() => setError("")}
        title="Error"
        description={error}
        type="error"
      />
    </>
  );
};

export default StakeModal;
