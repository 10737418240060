export const formatNumber = (num: number, digits?: number) =>
  new Intl.NumberFormat("en", {
    maximumFractionDigits: digits || 2,
  }).format(num);

export const formatPercentage = (percent: number, digits?: number): string =>
  new Intl.NumberFormat("en", {
    style: "percent",
    maximumFractionDigits: digits || 0,
  }).format(percent || 0);
