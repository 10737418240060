import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { SecretNetworkClient } from "secretjs";
import { useAccount, useSecretNetworkClient } from "./account";

var sleepSetTimeout_ctrl: any;

function sleep(ms: number) {
  clearInterval(sleepSetTimeout_ctrl);
  return new Promise(
    (resolve) => (sleepSetTimeout_ctrl = setTimeout(resolve, ms))
  );
}

const chainId = process.env.NEXT_PUBLIC_DEFAULT_CHAIN_ID || "";
const finaToken = process.env.NEXT_PUBLIC_FINA_TOKEN_ADDRESS || "";
const finaCodeHash = process.env.NEXT_PUBLIC_FINA_TOKEN_CODE_HASH || "";
const vestingContract = process.env.NEXT_PUBLIC_VESTING_CONTRACT_ADDRESS || "";
const vestingContractCodeHash =
  process.env.NEXT_PUBLIC_VESTING_CONTRACT_CODE_HASH || "";
const stakingContract = process.env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS || "";
const stakingContractCodeHash =
  process.env.NEXT_PUBLIC_STAKING_CONTRACT_CODE_HASH || "";
const queryAuthContract =
  process.env.NEXT_PUBLIC_QUERY_AUTH_CONTRACT_ADDRESS || "";
const queryAuthContractCodeHash =
  process.env.NEXT_PUBLIC_QUERY_AUTH_CONTRACT_CODE_HASH || "";
const finaTokenDecimals = Number(
  process.env.NEXT_PUBLIC_FINA_TOKEN_DECIMALS || 6
);

export interface FinaToken {
  viewingKey: string;
  balance: number;
  isFetched: boolean;
}

const emptyFinaToken: FinaToken = {
  viewingKey: "",
  balance: 0,
  isFetched: false,
};

export const finaTokenState = atom<FinaToken>({
  key: "finaToken",
  default: emptyFinaToken,
});

export interface VestingInfo {
  claimed: number;
  claimable: number;
  end: number;
  start: number;
  vesting: number;
  isFetched: boolean;
}

const emptyVestingInfo: VestingInfo = {
  claimed: 0,
  claimable: 0,
  end: 0,
  start: 0,
  vesting: 0,
  isFetched: false,
};

export const vestingInfoState = atom<VestingInfo>({
  key: "vestingInfo",
  default: emptyVestingInfo,
});

export interface FinaCardTier {
  spendingRewards: number;
  spendingLimit: number;
  stakingEurRequirements: number;
  stakingFinaRequirements: number;
  tier: string;
  whitelisted: boolean;
}

export interface StakingInfo {
  staked: number;
  apr: number;
  unlockedAt: number;
  rewards: number;
  isFetched: boolean;
  isViewingKeySet: boolean;
  lockUpDays: number;
  totalStaked: number;
  finaCardTiers: {
    tiers: { [tier: string]: FinaCardTier };
    registered: number;
    quota: number;
    myTier?: FinaCardTier;
  };
}

const emptyStakingInfo: StakingInfo = {
  staked: 0,
  apr: 0,
  unlockedAt: 0,
  rewards: 0,
  isFetched: false,
  isViewingKeySet: false,
  lockUpDays: 0,
  totalStaked: 0,
  finaCardTiers: {
    tiers: {},
    registered: 0,
    quota: 0,
  },
};

export const stakingInfoState = atom<StakingInfo>({
  key: "stakingInfo",
  default: emptyStakingInfo,
});

const getFinaViewingKeyAndBalance = async (
  address: string,
  secretjs: SecretNetworkClient
) => {
  let finaViewingKey = "";
  try {
    finaViewingKey =
      (await window.keplr?.getSecret20ViewingKey(chainId, finaToken)) || "";
  } catch (err) {
    console.log(err);
  }
  let finaTokenBalance: any =
    secretjs && finaViewingKey
      ? await secretjs.query.compute.queryContract({
          contract_address: finaToken,
          code_hash: finaCodeHash,
          query: {
            balance: { key: finaViewingKey, address },
          },
        })
      : { balance: { amount: "0" } };
  if (!finaViewingKey || !finaTokenBalance.balance) {
    try {
      await window.keplr?.suggestToken(chainId, finaToken);
      finaViewingKey =
        (await window.keplr?.getSecret20ViewingKey(chainId, finaToken)) || "";
    } catch (err) {
      console.log(err);
    }
    finaTokenBalance =
      secretjs && finaViewingKey
        ? await secretjs.query.compute.queryContract({
            contract_address: finaToken,
            code_hash: finaCodeHash,
            query: {
              balance: { key: finaViewingKey, address },
            },
          })
        : { balance: { amount: "0" } };
  }
  console.log({ finaTokenBalance });
  return {
    viewingKey: finaViewingKey,
    balance:
      Number(finaTokenBalance.balance?.amount || 0) / 10 ** finaTokenDecimals,
  };
};

const fetchVestingInfo = async (
  address: string,
  secretjs: SecretNetworkClient
) => {
  const vest: any = await secretjs.query.compute.queryContract({
    contract_address: vestingContract,
    code_hash: vestingContractCodeHash,
    query: { unlock_stats: { user: address } },
  });
  const start = Number(vest.unlock_stats_response.start_ts) * 1000;
  const end = Number(vest.unlock_stats_response.end_ts) * 1000;
  const now = Date.now();
  const total =
    Number(vest.unlock_stats_response.total_amount) /
    10 ** Number(process.env.NEXT_PUBLIC_FINA_TOKEN_DECIMALS || "0");
  const claimed =
    Number(vest.unlock_stats_response.claimed_amount) /
    10 ** Number(process.env.NEXT_PUBLIC_FINA_TOKEN_DECIMALS || "0");
  const claimable = Math.max(
    ((Math.min(now, end) - start) / (end - start)) * total - claimed,
    0
  );
  const vesting = total - claimed - claimable;

  return {
    claimed: claimed || 0,
    claimable: claimable || 0,
    end: end || 0,
    start: start || 0,
    vesting: vesting || 0,
    isFetched: true,
  };
};

const fetchStakingInfo = async (
  address: string,
  viewingKey: string,
  secretjs: SecretNetworkClient
) => {
  let balance: any = await secretjs.query.compute.queryContract({
    contract_address: stakingContract,
    code_hash: stakingContractCodeHash,
    query: {
      balance: {
        auth: {
          viewing_key: {
            key: viewingKey,
            address: address,
          },
        },
      },
    },
  });
  if (!balance.balance) {
    try {
      const params = {
        sender: address,
        contract_address: queryAuthContract,
        code_hash: queryAuthContractCodeHash,
        msg: { set_viewing_key: { key: viewingKey } },
      };
      const tx = await secretjs.tx.compute.executeContract(params, {
        gasLimit: 200000,
        gasPriceInFeeDenom: 0.25,
      });
      console.log({ tx });
      if (!tx.jsonLog) {
        throw new Error(tx.rawLog);
      }
      let retry = 0;
      while (!balance.balance && retry < 5) {
        balance = await secretjs.query.compute.queryContract({
          contract_address: stakingContract,
          code_hash: stakingContractCodeHash,
          query: {
            balance: {
              auth: {
                viewing_key: {
                  key: viewingKey,
                  address: address,
                },
              },
            },
          },
        });
        retry += 1;
        await sleep(retry * 2000);
      }
    } catch (err) {
      console.log(err);
    }
  }
  console.log({ balance });
  const stakingInfo: any = await secretjs.query.compute.queryContract({
    contract_address: stakingContract,
    code_hash: stakingContractCodeHash,
    query: { staking_info: {} },
  });
  console.log({ stakingInfo });

  const staked = balance.balance
    ? Number(balance.balance.staked) /
      10 ** Number(process.env.NEXT_PUBLIC_FINA_TOKEN_DECIMALS || "0")
    : 0;

  let pendingRewardAmount = 0;
  stakingInfo.staking_info.info.reward_pools.forEach((pool: any) => {
    const now = Date.now() / 1000;
    const nextWeek = now + 86400 * 7;
    const end = Math.min(pool.end, nextWeek);
    pendingRewardAmount += Math.max(
      0,
      (pool.amount * (end - now)) / (pool.end - pool.start)
    );
  });
  const apr = Math.max(
    0,
    (52 * pendingRewardAmount) / stakingInfo.staking_info.info.total_staked
  );

  const unlockedAt = balance.balance ? balance.balance.unlock_date * 1000 : 0;
  const rewards =
    balance.balance && balance.balance.rewards[0]
      ? Number(
          balance.balance.rewards
            .map((r: any) => Number(r.amount))
            .reduce((a: number, b: number) => a + b, 0)
        ) /
        10 ** Number(process.env.NEXT_PUBLIC_FINA_TOKEN_DECIMALS || "0")
      : 0;

  const finaCardTiers = await fetch(
    `${process.env.NEXT_PUBLIC_CARD_API_URL}/finaCardTiers?staked=${staked}&unlockedAt=${unlockedAt}&address=${address}`
  ).then((r) => r.json());

  return {
    staked,
    apr,
    unlockedAt,
    rewards,
    lockUpDays: Math.round(
      Number(stakingInfo.staking_info.info.lock_period) / 86400
    ),
    isViewingKeySet: !!balance.balance,
    totalStaked:
      Number(stakingInfo.staking_info.info.total_staked) /
      10 ** Number(process.env.NEXT_PUBLIC_FINA_TOKEN_DECIMALS || "0"),
    finaCardTiers,
  };
};

let fetchingFinaToken = false;
let fetchingVestingInfo = false;
let fetchingStakingInfo = false;

export const useFinaTokenInfo = () => {
  const { account } = useAccount();
  const secretjs = useSecretNetworkClient();
  const [finaToken, setFinaToken] = useRecoilState(finaTokenState);
  const [vestingInfo, setVestingInfo] = useRecoilState(vestingInfoState);
  const [stakingInfo, setStakingInfo] = useRecoilState(stakingInfoState);

  const getFinaToken = useCallback(
    async (refetch?: boolean) => {
      if (
        account.address &&
        secretjs &&
        (refetch || !finaToken.isFetched) &&
        !fetchingFinaToken
      ) {
        fetchingFinaToken = true;
        const result = await getFinaViewingKeyAndBalance(
          account.address,
          secretjs
        );
        fetchingFinaToken = false;
        setFinaToken({ ...result, isFetched: true });
      }
    },
    [account.address, secretjs, finaToken.isFetched, setFinaToken]
  );

  useEffect(() => {
    getFinaToken();
  }, [getFinaToken]);

  const getVestingInfo = useCallback(
    async (refetch?: boolean) => {
      if (
        account.address &&
        secretjs &&
        (refetch || !vestingInfo.isFetched) &&
        !fetchingVestingInfo
      ) {
        fetchingVestingInfo = true;
        const result = await fetchVestingInfo(account.address, secretjs);
        fetchingVestingInfo = false;
        setVestingInfo({ ...result, isFetched: true });
      }
    },
    [account.address, secretjs, vestingInfo.isFetched, setVestingInfo]
  );

  useEffect(() => {
    getVestingInfo();
  }, [getVestingInfo]);

  const getStakingInfo = useCallback(
    async (refetch?: boolean) => {
      if (
        account.address &&
        finaToken.viewingKey &&
        secretjs &&
        (refetch || !stakingInfo.isFetched) &&
        !fetchingStakingInfo
      ) {
        fetchingStakingInfo = true;
        const result = await fetchStakingInfo(
          account.address,
          finaToken.viewingKey,
          secretjs
        );
        fetchingStakingInfo = false;
        setStakingInfo({ ...result, isFetched: true });
      }
    },
    [
      account.address,
      secretjs,
      stakingInfo.isFetched,
      setStakingInfo,
      finaToken.viewingKey,
    ]
  );

  useEffect(() => {
    getStakingInfo();
  }, [getStakingInfo]);

  useEffect(() => {
    if (!account?.address) {
      setStakingInfo(emptyStakingInfo);
      setFinaToken(emptyFinaToken);
      setVestingInfo(emptyVestingInfo);
    }
  }, [account?.address, setStakingInfo, setFinaToken, setVestingInfo]);

  return {
    finaToken,
    vestingInfo,
    getFinaToken,
    getVestingInfo,
    getStakingInfo,
    stakingInfo,
  };
};
